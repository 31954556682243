<template>
  <div class="container-fluid">
    <section class="container">
      <v-row>
        <v-col cols="12" md="7" class="position-rel" style="z-index: 2;">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Os subdistritos de Paracatu de Cima e de Paracatu de Baixo, pela
                divisão administrativa e territorial, pertencem ao Distrito de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_monsenhor' }"
                  >Monsenhor Horta</router-link
                >. Localizam-se a 34 km da sede de Mariana e a 9 km de Monsenhor
                Horta, a sudeste de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_ponte_do_gama' }"
                  >Ponte do Gama</router-link
                >. Ambas são atravessadas pelo Rio Gualaxo do Norte, fundamental
                para a realização de atividades sócio econômicas desde a
                formação original do povoamento.
              </p>
              <p>
                A região de Paracatu foi ocupada em função de práticas de
                extração de ouro nos rios e córregos, ao mesmo tempo em que se
                desenvolviam atividades agropastoris desde o século XVIII. O
                povoamento recebeu anteriormente a denominação de
                Teixeira<sup>1</sup>, e a partir de meados do século XIX a
                designação foi modificada para Paracatu. No século XIX, entre as
                décadas de 1820 e 1850, o povoado foi dividido em Paracatu de
                Cima e Paracatu de Baixo.
                <sup>2</sup>
              </p>
            </template>
          </Card>

          <Card borderColor="#e06919" class="mb-5" style="z-index: 4;">
            <template v-slot:conteudo>
              <p>
                Quanto à ocupação original, ocorreu em meados do século XVIII.
                Paracatu teve sua capela primitiva dedicada a São Francisco
                Xavier, como apontam documentos do início do século XIX.
                Posteriormente, não se sabe exatamente quando ou o motivo, Santo
                Antônio passou a ocupar o lugar de principal adoração desta
                localidade. Da primitiva capela, que se encontrava em avançado
                estado de degradação em 1940, só restaram as bases. Entre os
                anos 1950 e 1960, ao lado da capela original, foi construída uma
                outra, substituída nos anos 1990 pela edificação que se
                encontrava implantada em Paracatu de Baixo na época do
                rompimento da barragem.
              </p>
              <p>
                No território encontram-se remanescentes da ocupação inicial,
                como as antigas fazendas, e vestígios arqueológicos, a exemplo
                das ruínas de espaços de produção agropecuária e de extração de
                ouro.
              </p>
            </template>
          </Card>
        </v-col>

        <v-img
          class="position-abs"
          max-width="100%"
          style="left: 53%; top: 30%; opacity: 0.4; z-index: 1;"
          src="../../assets/sobre_territorio_paracatu_01.png"
        />
      </v-row>
    </section>
    <section class="brown__stripe">
      <div class="container">
        <v-row
          class="d-flex justify-space-between align-center flex-row-reverse"
        >
          <v-col
            cols="12"
            md="7"
            class="d-flex flex-column justify-start align-center"
          >
            <Card borderColor="#e06919" class="mb-5" style="z-index: 2;">
              <template v-slot:conteudo>
                <p>
                  Os dois subdistritos desenvolveram-se ao longo do caminho que
                  liga Monsenhor Horta a
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_pedras' }"
                    >Pedras</router-link
                  >, sendo a sua fundação posterior à origem dessas duas
                  localidades. A estrada apresenta pavimentação asfáltica e,
                  anteriormente ao rompimento, possuía tráfego reduzido de
                  veículos, utilizada predominantemente por moradores e
                  produtores da região. Até mesmo antes do desastre–crime, a
                  oferta de transporte coletivo era bastante limitada,
                  dificultando a vida dos moradores que precisavam deslocar-se
                  até Mariana para acessar comércios e serviços.
                </p>
                <p>
                  Desde sua formação até a passagem do rejeito tóxico, Paracatu
                  era pouco adensado, tanto na porção a sudeste, que ficou
                  caracterizada como Paracatu de Baixo, quanto a noroeste,
                  denominada Paracatu de Cima.
                </p>
                <p>
                  A economia local é principalmente de subsistência, baseada em
                  cultivo de hortas e frutíferas, lavouras de milho e feijão,
                  criação de aves, suínos e bovinos, além da produção de leite e
                  derivados. Também havia pontos de mineração do ouro no leito
                  do Rio Gualaxo, mesmo se tratando de empreendimentos de
                  pequeno vulto. A atividade de pesca também foi bastante
                  expressiva, tanto para consumo das próprias famílias, quanto
                  para recreação e relações de sociabilidade.
                </p>
                <p style="text-align: center">
                  <strong>Autoria: Ana Paula Alves Ferreira<sup>3</sup></strong>
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </div>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> O censo de Teixeira foi elaborado em 1821, uma década
              antes do realizado em Camargos e Bento Rodrigues. O cruzamento
              entre as informações presentes neste documento, os relatos feitos
              pelo Bispo de Mariana em 1823 e as declarações de propriedade
              presentes no Registro de Terras Públicas de Paracatu de 1855
              sugere que Teixeira foi a antiga denominação de Paracatu entre o
              século XVIII e meados do século XIX. Os Registros de Terras
              Públicas, também conhecidos como Registros Paroquiais de Terras,
              foram os primeiros instrumentos a regulamentar o acesso à
              propriedade fundiária após o fim da concessão de sesmarias, em
              1822 - Relatório de História, Diagnóstico Preliminar dos Bens
              Culturais Identificados no Território Atingido.
            </p>
            <p>
              <sup>2</sup> As informações a respeito do histórico e da
              caracterização das comunidades tiveram como base principal o
              Diagnóstico preliminar dos bens culturais identificados no
              território atingido em Mariana pelo rompimento da barragem de
              Fundão, elaborado em 2017 pela empresa Pólen Consultoria
              Patrimônio e Projetos Ltda. O Diagnóstico foi encomendado pela
              Comissão dos Atingidos e pela Cáritas Brasileira MG e juntamente
              com os dados do cadastro realizado pela Cáritas, possibilitou um
              entendimento mais amplo das relações culturais, sociais e
              econômicas que foram consolidadas nesse território ao longo dos
              séculos. O trabalho abrange aspectos geográficos, históricos,
              arqueológicos, arquitetônicos, antropológicos e museológicos.
              Contudo, ainda se trata de um estudo e diagnóstico preliminar,
              sendo necessário a ampliação do conhecimento sobre as comunidades
              atingidas.
            </p>
            <p>
              <sup>3</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
.brown__stripe {
  position: relative;
}

.brown__stripe:before {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  height: 110px;
  width: 200%;
  background-color: #cd6b3e;
}
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
